<template>
  <div class="main-block">
    <div class="d-flex justify-content-center align-items-center" v-if="loading" style="height: 90vh">
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <b-card title="Статистика заказов по дням">
        <app-echart-stacked-area :option-data="orderDataByDay" />
      </b-card>
      <b-card title="Статистика заказов за последние 24 часа">
        <app-echart-stacked-area :option-data="orderDataByHour" />
      </b-card>
      <b-card title="Статистика заказов за последний год">
        <app-echart-stacked-area :option-data="orderDataByMonth" />
      </b-card>
    </div>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import AppEchartStackedArea from '@core/components/charts/echart/AppEchartStackedArea.vue'

export default {
  components: {
    BCard,
    AppEchartStackedArea,
  },
  data() {
    return {
      timeIntervalIdentifier: null,
      orderDataByDay: {
        xAxisData: [],
        series: [],
      },
      orderDataByHour: {
        xAxisData: [],
        series: [],
      },
      orderDataByMonth: {
        xAxisData: [],
        series: [],
      },
      loading: false
    }
  },
  mounted() {
    this.getAllData()
    this.timeIntervalIdentifier = setInterval(() => {
      this.getAllData()
    }, 3600000)
  },
  beforeDestroy() {
    clearInterval(this.timeIntervalIdentifier)
  },
  methods: {
    getDataWithDash() {
      return this.$http.get('dashboard-main')
    },
    getOrderDataByHour() {
      return this.$http.get('dashboard-last-24hour')
    },
    getOrderDataByMonth() {
      return this.$http.get('dashboard/orders-report')
    },
    getAllData() {
      this.loading = true
      Promise.all([this.getDataWithDash(), this.getOrderDataByHour(), this.getOrderDataByMonth()])
      .then(([orderDataByDay, orderDataByHour, orderDataByMonth]) => {
        this.orderDataByDay.xAxisData.length = 0
        this.orderDataByDay.series.length = 0
        this.orderDataByHour.xAxisData.length = 0
        this.orderDataByHour.series.length = 0
        this.orderDataByMonth.xAxisData.length = 0
        this.orderDataByMonth.series.length = 0
        console.log(orderDataByMonth.data.data[0]);
        this.orderDataByMonth.xAxisData = orderDataByMonth.data.months
        this.orderDataByDay.xAxisData = orderDataByDay.data.axis
        this.orderDataByHour.xAxisData = orderDataByHour.data.hours
        this.orderDataByMonth.series.push({
          name: 'Поступило',
          type: 'line',
          symbolSize: 5,
          itemStyle: {
          },
          lineStyle: {
            width: 2,
          },
          data: orderDataByMonth.data.data[0].data
        })
        this.orderDataByMonth.series.push({
          name: 'Завершено',
          type: 'line',
          symbolSize: 5,
          itemStyle: {
          },
          color: 'green',
          lineStyle: {
            width: 2,
          },
          data: orderDataByMonth.data.data[0].completed
        })
        this.orderDataByMonth.series.push({
          name: 'Отменено',
          type: 'line',
          symbolSize: 5,
          itemStyle: {
          },
          color: 'red',
          lineStyle: {
            width: 2,
          },
          data: orderDataByMonth.data.data[0].cancelled
        })
        this.orderDataByDay.series.push({
          name: 'Поступило',
          type: 'line',
          symbolSize: 5,
          itemStyle: {
          },
          lineStyle: {
            width: 2,
          },
          data: orderDataByDay.data.series[0].data
        })
        this.orderDataByDay.series.push({
          name: 'Отменено',
          type: 'line',
          symbolSize: 5,
          itemStyle: {
          },
          color: 'red',
          lineStyle: {
            width: 2,
          },
          data: orderDataByDay.data.series[0].cancelled
        })
        this.orderDataByDay.series.push({
          name: 'Завершено',
          type: 'line',
          symbolSize: 5,
          itemStyle: {
          },
          color: 'green',
          lineStyle: {
            width: 2,
          },
          data: orderDataByDay.data.series[0].complated
        })
        this.orderDataByHour.series.push({
          name: 'Поступило',
          type: 'line',
          symbolSize: 5,
          itemStyle: {
          },
          lineStyle: {
            width: 2,
          },
          data: orderDataByHour.data.series[0].recevied
        })
        this.orderDataByHour.series.push({
          name: 'Завершено',
          type: 'line',
          symbolSize: 5,
          itemStyle: {
          },
          color: 'green',
          lineStyle: {
            width: 2,
          },
          data: orderDataByHour.data.series[0].complate
        })
        this.orderDataByHour.series.push({
          name: 'Отменено',
          type: 'line',
          symbolSize: 5,
          itemStyle: {
          },
          color: 'red',
          lineStyle: {
            width: 2,
          },
          data: orderDataByHour.data.series[0].canceled
        })
        this.loading = false
        this.$store.commit('REFRESH_DATA', false)
      })
      .catch((err)=> {
        this.loading = false
      })
    }
  },
  computed: {
      fetchingNewData() {
        return this.$store.state.refresh.fetchingNewData
      }
    },
    watch: {
      fetchingNewData(val) {
        if (val) {
          this.loading = true
          this.getAllData()
        }
      }
    }
}
</script>

<style>
.main-block {
  overflow: scroll;
  height: calc(100vh - 92px);
}
</style>
